<template>
  <transition name="modal-component-fade">
    <div
      v-if="show"
      v-bind:id="id"
      class="modal-component"
      v-on:click="onBackdropClick">
      <div class="modal-component-content">
        <div class="modal-component-content-header flex-container">
          <h2 class="flex-item pancho-header-semibold--sm universal-color-neutral-coal">
            {{ header }}
          </h2>

          <p
            class="modal-component-close text--sm text-align--right"
            v-on:click="onCloseClick">
            <svg
              viewBox="0 0 17.414 17.414"
              xmlns="http://www.w3.org/2000/svg">
              <g
                fill="none"
                stroke="#272d34"
                stroke-miterlimit="10"
                stroke-width="2">
                <path d="m.707.707 16 16" />
                <path d="m16.707.707-16 16" />
              </g>
            </svg>
          </p>
        </div>

        <div class="modal-component-content-body">
          <slot />

          <footer
            v-if="renderFooterSlot"
            class="modal-component-footer">
            <slot name="footer" />
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "Instances/BarkVue";

import { cssVarForInnerHeight } from "../../../bark_ui/utils/cssVars";

cssVarForInnerHeight();

const MODAL_COMPONENT_CLASS = "modal-component";

const Modal = {
  props: {
    id: {
      type: String,
      required: true
    },

    header: {
      type: String,
      required: true 
    },

    show: {
      type: Boolean,
      required: true
    } 
  },

  computed: {
    renderFooterSlot: function() {
      return !!this.$slots.footer;
    }
  },

  watch: {
    show: function() {
      const bodyEl = document.getElementsByTagName("body")[0];

      // When the modal is shown, we're disabling scrolling on the page so content inside the modal can be scrolled more easily.
      if(bodyEl) {
        bodyEl.style.overflow = this.show ? "hidden" : "";
      }
    }
  },

  methods: {
    onBackdropClick(e) {
      const elClass = e.target.getAttribute("class");

      if(!elClass) {
        return;
      }

      if(elClass.split().includes(MODAL_COMPONENT_CLASS)) {
        this.close();
      }
    },

    onCloseClick() {
      this.close();
    },

    close() {
      // NOTE: this.$emit deprecated in Vue3
      this.$emit("hide");
    },

    onEscapeKeyPress(e) {
      if (this.show && e.keyCode === 27) {
        this.close();
      }
    }
  },

  mounted() {
    document.addEventListener("keydown", this.onEscapeKeyPress);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscapeKeyPress);
  }
};

export default Modal;
</script>
