import Vue from "Instances/BarkVue";
import { pageProductLine } from "../../../javascript/utils/general";

let promoCallOutAmplitude = {
  trackEvent: () => { }
};

if (window.AmplitudeTracking) {
  promoCallOutAmplitude = AmplitudeTracking(window);
}

const PromoCallOut = {
  methods: {
    handleClick() {
      const eventName = `${pageProductLine()} Homepage Promo Widget Clicked`;
      promoCallOutAmplitude.trackEvent(eventName, {});
    }
  }
};

export default PromoCallOut;
