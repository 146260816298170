import Vue from "Instances/BarkVue";
import BarkButton from "CMS/button_component/BarkButton";
import PromoWidget from "CMS/promo_widget_component/PromoWidget";
import Hero from "CMS/hero_component/Hero";
import PromoCallOut from "CMS/promo_call_out_component/PromoCallOut";
import ProductBlock from "CMS/product_block_component/ProductBlock";
import ContentCarousel from "BarkUI/content_carousel_component/ContentCarousel.vue";
import ContentBlockCarousel from "BarkUI/content_block_carousel_component/ContentBlockCarousel.vue";
import EmailSignup from "CMS/email_signup_component/EmailSignup";
import CanadaModal from "../components/bark_ui/landing_pages/CanadaModal";
import Accordion from "CMS/accordion_component/AccordionComponent";

new Vue({
  el: document.getElementById("main"),

  components: {
    Accordion,
    BarkButton,
    CanadaModal,
    ContentCarousel,
    ContentBlockCarousel,
    EmailSignup,
    PromoCallOut,
    ProductBlock,
    PromoWidget,
    Hero
  },

  data() {
    return {
      globalAmplitude: AmplitudeTracking(window),
      scrolled: false,
      headerOffsetHeight: 0
    };
  },

  methods: {
    trackPageVisit() {
      this.globalAmplitude.identifyUser();

      const { leadCapturePageId, contentfulEntryId, contentfulSnapshotId } = window.pageData;
      const pageLoadProps = {
        "Lead Capture Page ID": leadCapturePageId || "None",
        "Contentful Entry ID": contentfulEntryId || "None",
        "Contentful Snapshot ID": contentfulSnapshotId || "None"
      };
      this.globalAmplitude.trackPageLoad(pageLoadProps);
    },

    triggerInitialTracking() {
      if (window.amplitude && window.amplitude.getInstance()) {
        this.trackPageVisit();
      } else {
        document.addEventListener("amplitude-loaded", this.trackPageVisit);
      }
    }
  },

  mounted() {
    this.triggerInitialTracking();
  }
});

new Vue({
  el: "#nanobar",
  name: "nanobar",
  data() {
    return {
      limitPosition: 100,
      lastPosition: 0,
      headerOffsetHeight: 0,
      siteHeader: null,
      secondaryBackgroundColor: null,
      backgroundColorChangeInterval: 5000
    };
  },
  mounted() {
    this.headerOffsetHeight = document.querySelector("#site-header").offsetHeight;
    this.secondaryBackgroundColor = this.$el.dataset.secondaryBackgroundColor;
    this.changeBackgroundColor(this.secondaryBackgroundColor, this.backgroundColorChangeInterval);
  },
  methods: {
    changeBackgroundColor(secondaryBackgroundColor, backgroundColorChangeInterval) {
      setInterval(
        function() {
          var nanobarContentWrapper = document.querySelector("#nanobar-content-wrapper");
          if (secondaryBackgroundColor) {
            nanobarContentWrapper.style.backgroundColor = secondaryBackgroundColor;
          }
          clearInterval(backgroundColorChangeInterval);
        }, this.backgroundColorChangeInterval);
    }
  }
});
