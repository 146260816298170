import Vue from "Instances/BarkVue";
import axios from "axios";

let emailSignupAmplitude = {
  trackEvent: () => { }
};

if (window.AmplitudeTracking) {
  emailSignupAmplitude = AmplitudeTracking(window);
}

const DEFAULT_BUTTON_TEXT = "Sign Up";
const SUCCESS_BUTTON_TEXT = "Success!";

const EmailSignup = {
  el: document.getElementById("homepage-email"),

  props: {
    list: {
      required: false,
      type: String,
      default: "barkbox"
    },

    boxFooterNewsletter: {
      required: false,
      type: Boolean,
      default: false
    },

    homepageType: {
      required: false,
      type: String,
      default: "homepage"
    },

    amplitudeEvents: {
      required: false,
      type: Object,
      default: () => ({
        success: "NewsletterSignup success",
        error: "NewsletterSignup error"
      })
    }
  },

  data: function() {
    return {
      buttonText: DEFAULT_BUTTON_TEXT,
      email: "",
      errors: {
        homepageEmail: false,
        emailErrorMessage: ""
      }
    };
  },

  methods: {
    handleSuccess() {
      let geq = window.geq;
      emailSignupAmplitude.trackEvent(this.amplitudeEvents.success, {});
      this.errors.homepageEmail = false;
      this.errors.emailErrorMessage = "";
      this.buttonText = SUCCESS_BUTTON_TEXT;
      const message = document.getElementById("newsletter-submit");
      if (geq) {
        geq.suppress();
      }
      message.focus();
      message.addEventListener("focusout", () => {
        this.resetButton();
      });
    },

    handleException() {
      this.errors.homepageEmail = true;
      this.errors.emailErrorMessage = "Enter a valid e-mail address";
      emailSignupAmplitude.trackEvent(this.amplitudeEvents.error, {});
    },

    trackEmailSubmitted() {
      if (EMAIL_REGEX.test(this.email)) {
        axios.post(document.getElementById("email-form").action, {
          bb_list: this.list,
          box_footer_newsletter: this.boxFooterNewsletter,
          homepage_type: this.homepageType,
          email: this.email
        });
        this.handleSuccess();
      }
      else {
        this.handleException();
      }
    },

    resetButton() {
      this.email = "";
      this.buttonText = DEFAULT_BUTTON_TEXT;
    }
  }
};

export default EmailSignup;
