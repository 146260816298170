<template>
  <div>
    <slot
      name="details"
      v-bind:detailsStyle="detailsStyle"
      v-bind:onSummaryClick="onSummaryClick" />
  </div>
</template>

<script>
import Vue from "Instances/BarkVue";

/*

  Accordion

  This component enhances the native open/close behavior of the HTML details element with animations.

  Notes:
  - We're using the Element Animation API: https://developer.mozilla.org/en-US/docs/Web/API/Element/animate
  - Browsers that don't support this feature will fallback to a native / non-animated behavior (over polyfilling).
*/

let accordionAmplitude = { trackEvent: () => {} };

if (window.AmplitudeTracking) {
  accordionAmplitude = AmplitudeTracking(window);
}

const Accordion = {
  props: {
    itemPosition: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      animation: null,
      isClosing: false,
      isOpening: false,
      detailsStyle: {
        height: "",
        overflow: ""
      }
    };
  },

  mounted() {
    this.$details = this.$el.querySelector("details");
    this.$summary = this.$el.querySelector("summary");
    this.$content = this.$el.querySelector(".content");
  },

  methods: {
    onSummaryClick(e) {
      if(typeof(this.$details.animate) === "function") {
        e.preventDefault();
      } else {
        return;
      }

      this.detailsStyle.overflow = "hidden";

      if (this.isClosing || !this.$details.open) {
        this.detailsStyle.height = `${this.$details.offsetHeight}px`;
        this.$details.open = true;

        window.requestAnimationFrame(this.open);
      } else if (this.isOpening || this.$details.open) {
        this.close();
      }
    },

    animate(open = true, startHeight, endHeight) {
      this.animation = this.$details.animate({
        height: [startHeight, endHeight]
      }, {
        duration: 200,
        easing: "ease-out"
      });
  
      if(open) {
        this.animation.onfinish = () => this.onAnimationFinish(true);
        this.animation.oncancel = () => this.isOpening = false;
      } else {
        this.animation.onfinish = () => this.onAnimationFinish(false);
        this.animation.oncancel = () => this.isClosing = false;
      }
    },

    open() {
      this.isOpening = true;

      const startHeight = `${this.$details.offsetHeight}px`;
      const endHeight = `${this.$summary.offsetHeight + this.$content.offsetHeight}px`;

      if(this.animation) {
        this.animation.cancel();
      }

      this.sendAmplitudeEvent(this.$summary.textContent, this.itemPosition)
      this.animate(true, startHeight, endHeight);
    },

    close() {
      this.isClosing = true;
      
      const startHeight = `${this.$details.offsetHeight}px`;
      const endHeight = `${this.$summary.offsetHeight}px`;
      
      if (this.animation) {
        this.animation.cancel();
      }
      
      this.animate(false, startHeight, endHeight);
    },

    onAnimationFinish(open) {
      this.$details.open = open;
      this.animation = null;
      this.isClosing = false;
      this.isOpening = false;
      this.detailsStyle = {
        height: "",
        overflow: ""
      };
    },

    sendAmplitudeEvent(itemName, itemPosition) {
      const eventName = "Contentful Accordion Click";
      const pageName = window.document.title;
      const props = {
        "Page Name": pageName,
        "Accordion Item Number": itemPosition,
        "Accordion Item Name": itemName
      };

      accordionAmplitude.trackEvent(eventName, props, true);
    }
  }
};

export default Accordion;
</script>
