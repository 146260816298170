import Vue from "Instances/BarkVue";
import { pageProductLine } from "../../../javascript/utils/general";

let promoWidgetAmplitude = {
  trackEvent: () => { }
};

if (window.AmplitudeTracking) {
  promoWidgetAmplitude = AmplitudeTracking(window);
}

const PromoWidget = {
  data() {
    return {
      hidden: false,
      clicked: false,
      created: false
    };
  },

  computed: {
    ariaLabel: function() {
      const start = this.hidden ? "Open" : "Close";
      return `${start} promotional message`;
    }
  },

  created() {
    this.created = true;
  },

  methods: {
    trackWidgetClick() {
      const eventName = `${pageProductLine()} Homepage Promo Widget Clicked`;
      promoWidgetAmplitude.trackEvent(eventName, {});
    },

    toggle() {
      if (!this.hidden) {
        this.trackWidgetClosed();
      }
      this.hidden = !this.hidden;
      this.clicked = true;
    },

    toggleWithFocus() {
      this.hidden = !this.hidden;
    },

    hasTabbed() {
      this.clicked = false;
    },

    trackWidgetClosed() {
      const eventName = `${pageProductLine()} Homepage Promo Widget Closed`;
      promoWidgetAmplitude.trackEvent(eventName, {});
    }
  }
};

export default PromoWidget;
