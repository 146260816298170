import Modal from "BarkUI/modal_component/Modal.vue";
import axios from "axios";

export default {
  props: {
    isBright: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Modal
  },

  data() {
    return {
      showCanadaModal: true,
      canadaEmail: "",
      disableCanadaModalSubmit: false
    };
  },

  methods: {
    closeCanadaModal() {
      this.showCanadaModal = false;
    },

    renderText() {
      if (this.isBright) {
        return "We’re sorry to say that we’re not currently shipping Bright to Canada. But don’t worry, we will in the near future!";
      } else {
        return "We have some bad news: we're having trouble getting our boxes over the border right now, so we've temporarily suspended new orders to Canadian addresses.";
      }
    }
  }
};
