import { debounce } from "lodash";

/*
  cssVarForInnerHeight()

  Helpful for times when 100vh won't work, due to browser discrepancies in its return value.

  A CSS only solution like https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
  would be preferable but failed in testing.
*/

export const cssVarForInnerHeight = () => {
  if(document.body.dataset["jsInnerHeight"]) return;

  document.body.dataset["jsInnerHeight"] = true;

  const docEl = document.documentElement;

  const setInnerHeight = _.debounce(() => { 
    docEl.style.setProperty('--js-inner-height', `${window.innerHeight}px`);
  }, 500);

  setInnerHeight();

  window.addEventListener('resize', setInnerHeight);

  window.removeEventListener("beforeunload", setInnerHeight);
};
